<template>
  <header v-if="showBannerOnSuccess" class="header" :class="{ 'custom-header': displayCustomBanner }">
    <b-container fluid="md">
      <b-row>
        <b-col lg="8" offset-lg="2" class="col-xxl-6 offset-xxl-3">
          <img v-if="displayCustomBanner" :src="customLogoUrl" class="custom-logo" alt="" />
          <img v-else src="https://images.rafflebox.ca/RaffleboxLogos/wordmark-green.svg" alt="" class="logo" />
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AppNav extends Vue {
  showBannerOnSuccess = true;

  get displayCustomBanner() {
    return this.$store.getters.displayCustomBanner;
  }

  get customLogoUrl() {
    if (this.$route.name === 'Addon') {
      return this.$store.state.addon.logoUrl;
    } else {
      return this.$store.state.event.logoUrl;
    }
  }

  get buttonColor() {
    return this.$store.getters.buttonColor.split(' ')[1];
  }

  get event() {
    return this.$store.state.event;
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.$route.name === 'Success' && this.displayCustomBanner) {
      this.showBannerOnSuccess = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid $slate;
}

.logo {
  width: 12.5rem;
}

.custom-header {
  margin-top: 2rem;
  padding: 0;
  border-bottom: none;
}

.custom-logo {
  width: 100%;
}

@media screen and (min-width: 48em) {
  .logo {
    width: 18.75rem;
  }

  .custom-header {
    margin-top: 2rem;
    padding: 1rem;
    border-bottom: none;
  }
}
</style>
