import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import App from './App.vue';
import router from './router';
import store from './store';


import '@rafflebox-technologies-inc/rafflebox-core-theme/dist/styles.min.css';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueTheMask from 'vue-the-mask';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, email, max, digits } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import JsonViewer from 'vue-json-viewer';
import config from '@/config';
import mixinMethods from '@/mixin';
import VueI18n from 'vue-i18n';
import translations from './i18n';
import VueGtag from 'vue-gtag';
import VueFacebookPixel from 'vue-facebook-pixel';
import VueCookie from 'vue-cookie';
import { validatePostal } from '@rafflebox-technologies-inc/rafflebox-locations';
import VueEllipseProgress from 'vue-ellipse-progress';
import { initializeUnleash } from '@/lib/unleash';
import { initDataDog } from '@/lib/datadog';


// Install BootstrapVue
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
Vue.use(VueTheMask as any);
Vue.use(JsonViewer);
Vue.use(VueGtag, {
  bootstrap: false
});
Vue.use(VueFacebookPixel);
Vue.use(VueEllipseProgress);
Vue.use(VueCookie);

await initializeUnleash();
initDataDog(config.ENV, config.GIT_COMMIT);

if (['test', 'production'].includes(config.ENV)) {
  Vue.use(VueGtm, {
    id: config.GOOGLE_TAG_MANAGER_CONTAINER_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  });
}

Vue.mixin({
  methods: mixinMethods
});

// Use vee-validate 3 and install rules
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: messages['required']
});
extend('min', {
  ...min,
  message: messages['min']
});
extend('max', {
  ...max,
  message: messages['max']
});
extend('digits', {
  ...digits,
  message: messages['digits']
});
extend('email', {
  ...email,
  message: messages['email']
});
extend('confirmEmail', {
  params: ['target'],
  validate(value: string, { target }: any) {
    return value === target;
  },
  message: 'Email confirmation does not match'
});
extend('postal', {
  validate(postalCode: string, { province }: any) {
    return validatePostal(province, postalCode);
  },
  params: ['province'],
  message: 'The postal code must be from within the province of the raffle.'
});
extend('phoneNumberLength', {
  validate(phoneNumber: string) {
    const cleanPhone = phoneNumber.replace(/[-+()\s]/g, '');

    if (cleanPhone.length !== 10) {
      return false;
    } else {
      return true;
    }
  },
  message: 'The phone number must be 10 digits'
});

extend('shippingPostal', {
  validate(postalCode: string, { province }: any) {
    return validatePostal(province, postalCode);
  },
  params: ['province'],
  message: 'The postal code must be from province selected above.'
});

new Vue({
  i18n: new VueI18n({
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: translations
  }),
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
