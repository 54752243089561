import { datadogLogs } from '@datadog/browser-logs';

export const initDataDog = (stage: string, release: string) => {
  datadogLogs.init({
    clientToken: 'pube207ab248424b5c1560854e66adf6292',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: stage,
    service: 'checkout',
    version: release
  });

  datadogLogs.setGlobalContextProperty('webVersion', release);
};

export const logger = datadogLogs.logger;
