<template>
  <div class="container">
    <ErrorHandler v-if="paymentError" :error="paymentError" />
    <p></p>
    <AgeCheck
      v-if="event.province !== 'SK' && canMakePayment"
      :country="customer.country"
      :event="event"
      :province="event.province"
      :validate-postal-code-location="!event.disablePostalCheckV2"
      :customer="customer"
    />
    <CASLOptInInput v-if="canMakePayment" :customer="customer" />

    <b-overlay :show="canMakePayment && !customer.caslOptIn && !customer.ageCheck">
      <div id="payment-request-button" ref="payment-button">
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <template #overlay><div></div></template>
    </b-overlay>
    <hr v-if="canMakePayment" class="hr-text" data-content="Or enter card details" />
  </div>
</template>
<script lang="ts">
declare const window: any;
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import PaymentService, {
  StripePaymentIntent,
  CartItem,
  StripePaymentIntentResult,
  StripeGoldrushPaymentIntentResult,
  isGoldrushIntent
} from '@/lib/services/payment-service';

import { validatePostal } from '@rafflebox-technologies-inc/rafflebox-locations';

import checkout from '@/model/checkout';
import shoppingCart from '@/model/shopping-cart';

import { Event as Raffle } from '@/lib/schema';
import { StripeErrorCodes } from '@/lib/error-codes';
import {
  ErrorCodes,
  CountryProvinceAgeModel,
  Country,
  ProvinceState
} from '@rafflebox-technologies-inc/rafflebox-schema';

import CASLOptInInput from '@/components/CASLOptInInput.vue';
import AgeCheck from '@/components/AgeCheck.vue';
import { ShippingInformationData } from './ShippingInformation.vue';
import { getProvinceStateOptions } from '@/lib/province-options';

interface Customer {
  caslOptIn: boolean | null;
  ageCheck: boolean | null;
  country: string | null;
}

export default Vue.extend({
  name: 'StripeGoogleApplePayment',
  components: {
    CASLOptInInput,
    AgeCheck
  },
  props: {
    cart: {
      type: Array,
      required: true
    },
    donationAmount: {
      type: Number,
      required: false,
      default: null
    },
    numDraws: {
      type: Number,
      required: false,
      default: null
    },
    customer: {
      type: Object as PropType<Customer>,
      required: true
    },
    showCreateOnlineOrderButton: {
      type: Boolean,
      required: true
    },
    shippingInformation: {
      type: Object as () => ShippingInformationData,
      required: false,
      default: null
    }
  },
  data() {
    return {
      event: this.$store.state.event as Raffle,
      paymentError: false as boolean | string,
      purchaseInProgress: false,
      canMakePayment: false,
      validationError: false,
      orders: [] as any[],
      successParams: {} as { [k: string]: any },
      provinceOptions: {} as ProvinceState[]
    };
  },
  computed: {
    ...mapGetters(['isGoldrush'])
  },
  watch: {
    async donationAmount() {
      await this.initGoogleApplePay();
    }
  },
  async mounted() {
    await this.initGoogleApplePay();
    this.provinceOptions = getProvinceStateOptions(
      this.event.country,
      this.event.province,
      this.event.disablePostalCheckV2
    );

    if (this.$i18n.locale === 'fr') {
      this.provinceOptions.forEach((option: any) => {
        switch (option.name) {
          case 'Colombie-Britannique':
            option.name = 'British Columbia';
            break;
          case 'Nouveau-Brunswick':
            option.name = 'New Brunswick';
            break;
          case 'Terre-Neuve-et-Labrador':
            option.name = 'Newfoundland and Labrador';
            break;
          case 'Nouvelle-Écosse':
            option.name = 'Nova Scotia';
            break;
          case 'Île-du-Prince-Édouard':
            option.name = 'Prince Edward Island';
            break;
          case 'Québec':
            option.name = 'Quebec';
            break;
          case 'Territoires du Nord-Ouest':
            option.name = 'Northwest Territories';
            break;

          case 'Saskatchewan':
          case 'Alberta':
          case 'Manitoba':
          case 'Nunavut':
          case 'Ontario':
          case 'Yukon':
            break;
        }
      });
    }
  },
  methods: {
    async initGoogleApplePay() {
      try {
        const elements = this.$store.state.stripe.elements();

        const paymentRequest = this.$store.state.stripe.paymentRequest({
          country: this.event.country,
          currency: CountryProvinceAgeModel[this.event.country as Country].currency.toLowerCase(),
          total: {
            label: `${this.event.name} - ticket purchase`,
            amount: Math.round(shoppingCart.totalCartWithProcessingFee() * 100)
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestPayerPhone: true
        });

        const prButton: any = elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest
        });

        // Check the availability of the Payment Request API first.
        const result = await paymentRequest.canMakePayment();

        // TODO - remove after Calgary Northstars Raffle
        if (result && this.event.id !== '83e38484-990a-4691-9d17-ce1b6447bb1c') {
          console.log('Payment Request API is available');
          this.canMakePayment = true;
          prButton.mount('#payment-request-button');
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          document.getElementById('payment-request-button')!.style.display = 'none';
        }

        prButton.on('click', (e: Event) => {
          if (!this.customer.caslOptIn && !this.customer.ageCheck) {
            e.preventDefault();
          }
        });

        // On purchase attempt this event handler is fired
        paymentRequest.on('paymentmethod', async (ev: any) => {
          this.purchaseInProgress = true;
          this.$emit('enable-spinner');

          const name = ev.paymentMethod.billing_details.name as string;
          // TODO - Needs a check on Postal Code to match the API.
          const postalCode = ev.paymentMethod.billing_details.address.postal_code as string;
          const cleanPostal = postalCode.replace(' ', '');

          const phone = ev.paymentMethod.billing_details.phone as string;
          // Remove logger after new method is created.

          let cleanPhone = phone.replace(/\D/g, '');
          // Truncate phone number to 10 digits (removing possible country codes)
          if (cleanPhone.length > 10) {
            const tenDigitPhone = cleanPhone.slice(-10);
            cleanPhone = tenDigitPhone;
          }

          const splitName = name.split(' ');
          const firstName = splitName[0];
          const lastName = name.substring(firstName.length).trim();
          let confirmPaymentResult: any;

          try {
            if (!validatePostal(this.event.province, cleanPostal) && !this.event.disablePostalCheckV2) {
              this.validationError = true;
              ev.complete('fail');
              throw new Error(ErrorCodes.PostalCodeNotInProvince);
            }

            const paymentIntent: StripePaymentIntent = {
              eventId: this.event.id,
              email: ev.paymentMethod.billing_details.email,
              firstName: firstName,
              lastName: lastName,
              address: ev.paymentMethod.billing_details.address.line1,
              city: ev.paymentMethod.billing_details.address.city,
              province: ev.paymentMethod.billing_details.address.state,
              cardType: ev.paymentMethod.card.brand,
              country: this.event.country,
              postal: cleanPostal,
              phone: cleanPhone,
              eventMemberNumber:
                checkout.selectedEventMember && checkout.selectedEventMember !== -1
                  ? checkout.selectedEventMember.toString()
                  : undefined,
              campaignId: this.$route.query.cid as string,
              paymentRequestButton: true,
              cartItems: this.cart as CartItem[],
              caslOptIn: this.customer.caslOptIn ?? false
            };

            if (shoppingCart.totalDonationCents() > 0 && !this.isGoldrush) {
              paymentIntent.donationAmountCents = shoppingCart.totalDonationCents();
            }

            if (!this.isGoldrush) {
              paymentIntent.shippingAddressLine1 = this.shippingInformation.address;
              paymentIntent.shippingCity = this.shippingInformation.city;
              paymentIntent.shippingState = this.shippingInformation.province
                ? this.shippingInformation.province
                : null;
              paymentIntent.shippingPostal = this.shippingInformation.postal ? this.shippingInformation.postal : null;
            }

            if (this.isGoldrush) {
              paymentIntent.numDraws = this.numDraws;
            }

            let paymentIntentResult: StripePaymentIntentResult | StripeGoldrushPaymentIntentResult;
            if (this.isGoldrush) {
              paymentIntentResult = await PaymentService.createStripePaymentIntentGoldrush(paymentIntent);
            } else {
              paymentIntentResult = await PaymentService.createStripePaymentIntent(paymentIntent);
            }

            confirmPaymentResult = await this.$store.state.stripe.confirmCardPayment(
              paymentIntentResult.stripeClientSecret,
              {
                payment_method: ev.paymentMethod.id
              },
              {
                handleActions: false
              }
            );

            if (confirmPaymentResult.error) {
              ev.complete('fail');
              throw new Error(confirmPaymentResult.error);
            } else {
              ev.complete('success');

              await this.$store.dispatch('updatePaymentIntent', paymentIntent).then(() => {
                this.orders = paymentIntentResult.orders.map((order) => {
                  return {
                    eventId: order.eventId,
                    orderId: order.id.substr(0, 8).toUpperCase(),
                    orderIdLong: order.id
                  };
                });

                this.successParams.orders = JSON.stringify(this.orders);

                if (isGoldrushIntent(paymentIntentResult)) {
                  this.successParams.numDraws = paymentIntentResult.numDraws;
                  this.successParams.lastDrawDate = paymentIntentResult.lastDrawDate;
                }
              });

              if (this.showCreateOnlineOrderButton) {
                this.successParams.showCreateOnlineOrderButton = true;
                this.successParams.fullPath = this.$route.fullPath;
              }

              if (confirmPaymentResult.paymentIntent.status === 'requires_action') {
                // Let Stripe.js handle the rest of the payment flow.
                confirmPaymentResult = await this.$store.state.stripe.confirmCardPayment(
                  paymentIntentResult.stripeClientSecret
                );
                if (confirmPaymentResult.error) {
                  ev.complete('fail');
                  throw new Error();
                } else {
                  // The payment has succeeded.
                  this.$router.push({
                    name: 'Success',
                    params: { ...this.successParams }
                  });
                }
              } else {
                // Add purchase to GA
                if (this.$gtm.enabled()) {
                  // Build array of current cart
                  const products = shoppingCart.items().map((tp) => {
                    const price = tp.priceCents / 100;

                    return {
                      // Linter complains about non-camelCase
                      /* eslint-disable */
                      item_id: tp.id,
                      item_name: this.$store.state.event.name,
                      price: price,
                      item_category: this.$store.state.event.category,
                      quantity: tp.quantity,
                      item_variant: `${tp.numTickets} for $${price}`,
                      index: 0
                      /* eslint-enable */
                    };
                  });

                  const totalPurchased = shoppingCart.totalCart();

                  (window as any).dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                      transaction_id: confirmPaymentResult.paymentIntent.id,
                      currency: this.$store.state.event.currency.toUpperCase(),
                      value: totalPurchased,
                      items: products
                    }
                  });
                }

                // The payment has succeeded.
                this.$router.push({
                  name: 'Success',
                  params: { ...this.successParams }
                });
              }
            }
          } catch (error: any) {
            if (error.response && error.response.status === 400) {
              // Generic 400 error
              this.paymentError = StripeErrorCodes.StripeGeneric400;
            } else if (error.response && error.response.status == 500) {
              // Generic 500 error
              this.paymentError = StripeErrorCodes.StripeGeneric500;
            }
          }
          this.purchaseInProgress = false;

          if (this.validationError) {
            this.paymentError = ErrorCodes.PostalCodeNotInProvince;

            this.$emit('disable-spinner');
            this.$nextTick(() => {
              this.$emit('scroll-to-alert');
            });
          }

          if (confirmPaymentResult && confirmPaymentResult.error) {
            const errorCode = confirmPaymentResult.error.code;
            const declineCode = confirmPaymentResult.error.decline_code || 'Unknown';

            // If there is a decline_code, only display generic message
            // But log the actual message
            if (declineCode !== 'Unknown') {
              this.paymentError = StripeErrorCodes.StripeGenericDecline;
            } else {
              // Else pass error to errorHandler.
              this.paymentError = errorCode;
            }

            this.$emit('disable-spinner');
            this.$nextTick(() => {
              this.$emit('scroll-to-alert');
            });
          }
        });
      } catch (error: any) {
        this.$emit('stripe-intent-error', `Loading stripe payment form failed - ${error.message}`);
      }
    }
  }
});
</script>
<style lang="scss">
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}
</style>
