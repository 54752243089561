<template>
  <div></div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { Country, CountryProvinceAgeModel } from '@rafflebox-technologies-inc/rafflebox-schema';

import config from '@/config';
import { logger } from '@/lib/datadog';

export default Vue.extend({
  name: 'UserLocation',
  props: {
    country: {
      type: String,
      required: true
    },
    eventProvince: {
      type: String,
      required: true
    },
    sessionId: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userLocationProvince: '' as undefined | string,
      isInProvince: false as boolean
    };
  },
  mounted() {
    this.getLocationByIP();
  },
  methods: {
    async getLocationByIP(): Promise<void> {
      // Get Location by IP through ipstack service
      try {
        const protocol = ['test', 'production'].includes(config.ENV) ? 'https://' : 'http://';
        const result = await axios.get(
          `${protocol}api.ipstack.com/check?access_key=${config.IPSTACK_API_TOKEN}&fields=region_code`
        );
        if (!['test', 'production'].includes(config.ENV)) {
          console.log('IP Lookup result', result);
        }

        if (result.status === 200 && result.data.region_code === this.eventProvince) {
          this.isInProvince = true;
          logger.log(`Geolocation - User in province (IP Check)`, {
            checkoutSessionId: this.sessionId,
            eventProvince: this.event.province,
            eventName: this.event.name,
            eventID: this.event.id,
            organizationId: this.event.organizationId
          });
          this.$emit('userLocationFound', this.isInProvince);
        } else {
          this.findUserLocation();
        }
      } catch (_error) {
        this.findUserLocation();
      }
    },
    findUserLocation(): void {
      let pos: any;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          pos = position;
          this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          logger.log(`Geolocation - Failed to get latitude/longitude ${error.message}`, {
            checkoutSessionId: this.sessionId,
            eventProvince: this.event.province,
            eventName: this.event.name,
            eventID: this.event.id,
            organizationId: this.event.organizationId,
            latitude: pos?.coords?.latitude || 0,
            longitude: pos?.coords?.longitude || 0
          });
          this.$emit('userLocationFound', false, new Error(`Failed to get latitude/longitude ${error.message}`));
        }
      );
    },
    async getStreetAddressFrom(lat: number, long: number): Promise<void> {
      try {
        const { data } = await axios.get(
          'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            long +
            '&key=AIzaSyC6cA6L9u240P8krxbFToflAbMt2wJd7cw'
        );

        if (!['test', 'production'].includes(config.ENV)) {
          console.log('Street Lookup', data);
        }

        if (data.error_message) {
          logger.log(`Geolocation - Failed to get address ${data.error_message}`, {
            checkoutSessionId: this.sessionId,
            eventProvince: this.event.province,
            eventName: this.event.name,
            eventID: this.event.id,
            organizationId: this.event.organizationId,
            latitude: lat,
            longitude: long
          });

          this.$emit('userLocationFound', false, new Error(`Failed to get address ${data.error_message}`));
        } else {
          for (const ac of data.results[0].address_components) {
            if (ac.short_name === this.eventProvince) {
              this.isInProvince = true;
              logger.log(`Geolocation - User in province (Geo Check)`, {
                checkoutSessionId: this.sessionId,
                eventProvince: this.event.province,
                eventName: this.event.name,
                eventID: this.event.id,
                organizationId: this.event.organizationId,
                latitude: lat,
                longitude: long
              });
              break;
            } else {
              const province = CountryProvinceAgeModel[this.country as Country].provinceState.find(
                (item) => item.code === ac.short_name
              );
              if (province) {
                this.userLocationProvince = province.code;
              }
            }
          }

          // Capture the user out of province error as a log
          if(!this.isInProvince) {
            logger.log(`Geolocation - is not in province`, {
              checkoutSessionId: this.sessionId,
              eventProvince: this.event.province,
              eventName: this.event.name,
              eventID: this.event.id,
              organizationId: this.event.organizationId,
              latitude: lat,
              longitude: long
            });
          }

          this.$emit('userLocationFound', this.isInProvince);
        }
      } catch (error) {
        logger.log(`Geolocation - error`, {
          checkoutSessionId: this.sessionId,
          eventProvince: this.event.province,
          eventName: this.event.name,
          eventID: this.event.id,
          organizationId: this.event.organizationId,
          latitude: lat,
          longitude: long
        });
        this.$emit('userLocationFound', false, error);
      }
    }
  }
});
</script>
